import React from 'react'
import {Button, Tooltip} from '@material-ui/core'
import {DataGridPro,  LicenseInfo } from '@mui/x-data-grid-pro';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {Edit} from '@mui/icons-material';
import {format, isWithinInterval, parseISO} from 'date-fns'
import { Check,Cancel } from '@mui/icons-material';

import _ from 'lodash/fp'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getAllMoots} from "../../reducers/mootSelectors";
import {dateTimeFormatter, getCurrentHongKongTime, hongKongTimeFromUTC} from "../../utils/dateUtils";
import {useHistory} from "react-router-dom";
import {Create, SaveAlt} from "@material-ui/icons";
import {utcToZonedTime} from "date-fns-tz";
import {activateMoot} from "../../actions/mootActions";

LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);

  const MootTable = ({ moots = [], getAllMoots = _.noop, activateMoot = _.noop }) => {
    const history = useHistory()


    const [busy, setBusy] = React.useState(false)

    React.useEffect(() => {
      const asyncFetchData = async () => {
        try {
          setBusy(true)
          await getAllMoots()
          setBusy(false)
        } catch (e) {
          //TODO ?
          setBusy(false)
        }
      }
      asyncFetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const isTodayWithinAMootDate = allMoots => {
       const now = new Date(getCurrentHongKongTime())
      return _.all(m => {
        if (_.isNil(m.mootStartDate) || _.isNil(m.mootEndDate))
          return false

        return isWithinInterval(now, {
          start: new Date(m.mootStartDate),
          end: new Date(m.mootEndDate),
        })
      }  ,allMoots)
    }

    const cannotActivateMoot = moot => {
      // If it is already the active moot you can't activate it
      if (moot.active)
        return true

      //if todays date is outside of the open/close date of all moots in the list
      // then it CAN be activated
      return isTodayWithinAMootDate(moots)


    }
    const onSetActiveMoot  = async id => {
        //Set as active moot
        try {
            setBusy(true)
            await activateMoot(id)
            setBusy(false)
        } catch (e) {
            //TODO ?
            setBusy(false)
        }
    }

    const onEdit = id => {
      history.push(`/admin/moot/edit/${id}`)

    }
    const columns = [
      {
        field: 'mootNumber',
        headerName: 'Moot #',
        flex: 1,
      },
      {
        field: 'mootStartDate',
        headerName: 'Moot Open Date HKT',
        flex: 1,
        valueGetter: params => params.row.mootStartDate ? format(utcToZonedTime(params.row.mootStartDate,'Asia/Hong_Kong'),'yyyy/MM/dd hh:mm aaa') : 'not set',
      },
      {
        field: 'mootEndDate',
        headerName: 'Moot Close Date HKT',
        flex: 1,
        valueGetter: params => params.row.mootEndDate ? format(utcToZonedTime(params.row.mootEndDate,'Asia/Hong_Kong'),'yyyy/MM/dd hh:mm aaa'): 'not set',
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        getActions: params => [
          <GridActionsCellItem label="Set as active moot"
                               onClick={() => onSetActiveMoot(params.row.id)}
                               icon={
                                  <Tooltip title='Set as active moot'>
                                      <Check htmlColor={params.row.active ? 'green': ''} />
                                  </Tooltip>
                               }
                               disabled={cannotActivateMoot(params.row)} />,
          <GridActionsCellItem label="Edit" onClick={() => onEdit(params.row.id)}
                               icon={
                                 <Tooltip title='Edit'>
                                   <Edit/>
                                 </Tooltip>
                               } />

        ]
      }
    ]
    return (
        <div style={{height: 700, width: '100%'}}>

          <Button color="primary"
                  style={{ margin: '.5rem 0'}}
                  size="medium"
                  startIcon={<Create />}
                  aria-haspopup="menu"
                  onClick={() => {
                    history.push(`/admin/moot/new`)

                  }}
          >New Moot</Button>

          <DataGridPro
              loading={busy}
              rows={moots}
              columns={columns}
              disableSelectionOnClick
          />
        </div>
    )
  }


MootTable.propTypes = {
  moots: PropTypes.arrayOf(PropTypes.object),
  getMoots: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
      moots: state.moot.allMoots
    }),{
      getAllMoots: getAllMoots,
      activateMoot : activateMoot
    })(MootTable)
