import React, {useMemo} from 'react'
import {Button, Link, Tooltip} from '@material-ui/core'
import {DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, LicenseInfo, GridFooterContainer, GridFooter} from '@mui/x-data-grid-pro';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {noop, join, isEmpty, last,get, filter, head} from 'lodash/fp';
import {Check, Pause, DoNotDisturb, Delete,Payment} from '@mui/icons-material';
import { format, parseISO } from 'date-fns'
import {utcToZonedTime} from "date-fns-tz";
import {connect} from "react-redux";
import {getAllTeams} from "../../../reducers/adminSelectors";
import {setTeamStatus, exportAllTeamMembers,exportAllTeamPaymentRecords, downloadTeamDocument} from "../../../actions/adminActions";
import PropTypes from "prop-types";
import {SaveAlt} from "@material-ui/icons";
import {isNotEmpty} from "../../../utils/funcUtils";
import { clientsideDownloadFile, formatCurrency} from "../../helpers";
import {useHistory} from "react-router-dom";
import TeamName from 'components/team/TeamName';
LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);
const TeamsTable = ({ teams = [],
                      getTeams = noop,
                      setTeamStatus = noop,
                      exportAllTeamMembers = noop,
                      exportAllTeamPaymentRecords = noop,
                      downloadTeamDocument = noop}) => {

  const [busy, setBusy] = React.useState(false)
  const [totalAccepted, setTotalAccepted] = React.useState(0);

  const history = useHistory()

  const onSetTeamStatus = async (id,status) =>{
    try {
      setBusy(true)
      await setTeamStatus(id,status)
      const allTeams = await getTeams(true)
      setTotalAccepted(calculateTotalAcceptedSum(allTeams))
      setBusy(false)
    }catch(e){
      //TODO ?
      setBusy(false)
    }

  }
  React.useEffect(() => {
    const asyncFetchData = async () => {
      try {
        setBusy(true)
        const allTeams = await getTeams()
        setBusy(false)
        setTotalAccepted(calculateTotalAcceptedSum(allTeams))
      }catch(e){
        //TODO ?
        setBusy(false)
      }
    }
    asyncFetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const buildFile = (data,filename)  => {
    const blob = new Blob([join('\n',data)], {
      type: 'text/tab-separated-values',
    });

    const fullName = `${filename} - ${format(new Date(),'yyyy-MM-dd')}.tsv`;
    clientsideDownloadFile(blob,fullName)
  }

  const exportTeamMembers = async () => {
    try {
      setBusy(true)
      const data = await exportAllTeamMembers()
      buildFile(data, 'Team Members')
    }finally {
      setBusy(false)
    }
  }

  const exportPaymentRecords = async () => {
    try {
      setBusy(true)
      const data = await exportAllTeamPaymentRecords()
      buildFile(data, 'Team Payment Records')
    }finally {
      setBusy(false)
    }
  }

  const downloadDocument = async (id,memoType,school) => {
    try {
      setBusy(true)
      const url = await downloadTeamDocument(id)
      const a = document.createElement('a')
      a.href = url
      a.download = `${school} - ${memoType}.pdf`
      a.click()
    }
    finally {
        setBusy(false)
    }

  }

  function CustomToolbar() {
    return (
        <GridToolbarContainer style={{gap: '15px' }}>
          <GridToolbarColumnsButton className="toolbar-button" />
          <GridToolbarFilterButton className="toolbar-button" />
          <GridToolbarDensitySelector className="toolbar-button" />          
          <Tooltip title='Export contains visible columns of the grid'>
            <GridToolbarExport
                  className="toolbar-button"
                  printOptions={{ disableToolbarButton: true }}
                  csvOptions={{
                      fileName: `Schools - ${format(new Date(),'yyyy-MM-dd')}`,
                      utf8WithBom: true,
                  }}                  
            />
          </Tooltip>
          <Button color="primary"
                size="small"
                startIcon={<SaveAlt />}
                aria-haspopup="menu"
                onClick={exportTeamMembers}>Export Team Members</Button>
          <Button color="primary"
                  size="small"
                  startIcon={<SaveAlt />}
                  aria-haspopup="menu"
                  onClick={exportPaymentRecords}>Export All Payment Records</Button>
        </GridToolbarContainer>
    );
  }

  function CustomFooter () {
    return (
      <GridFooterContainer style={{justifyContent: 'end'}}>
        <div style={{margin: '0px 16px'}}>Total Accepted Teams: {totalAccepted}</div>
        <GridFooter sx={{
          border: 'none', // To delete double border.
          }} />          
      </GridFooterContainer>
    );
  }

  const calculateTotalAcceptedSum = (data) => {    
    return filter(t=>t.status === 'accepted', data).length
  }

  const previousMootCountNumber =  (mootCount) => {
    if (mootCount === '1-5') {
      return (1)
    } else if (mootCount === '6-10') {
      return (6)
    } else if (mootCount === '11+') {
      return (11)
    } else {
      return mootCount
    }
  }

  const inPersonHeadCount = (members) => {
    let membersWillBeInPerson = members.filter(function (el) {
      return el.willBeInPerson
    });

    return membersWillBeInPerson.length
  }

  const onEditPayments = teamId => {
    history.push(`/admin/team/payments/${teamId}`)
  }

  const getAcceptIcon = (row) => {
    if (row.PercentageOfTeamsFromThisJurisdictionIfAccepted > 20) {
      return (
          <Tooltip title={`Accepting this team will put ${row.jurisdiction} over 20%`}>
            <Check htmlColor={row.status === 'accepted' ? '' : 'red'}/>
          </Tooltip>
      )
    }else{
      return (
      <Tooltip title='Accept'>
        <Check htmlColor={row.status === 'accepted' ? '' : 'green'}/>
      </Tooltip>
      )
    }
  }
  const columns = useMemo(() => [
      {
        field: 'id',
        headerName: 'School Id',
        flex: 1,
        hide: true,
     },
     {
        field: 'school',
        headerName: 'School',
        flex: 1,
        renderCell: params => <TeamName team={params.row} adminUse={true} />,
        valueGetter: params => (params.row.school)
     },
     {
      field: 'street',
      headerName: 'Street',
      flex: 1,
      hide: true
    },
    {
      field: 'unit',
      headerName: 'Unit',
      flex: 1,
      hide: true
    },
      {
        field: 'city',
        headerName: 'City',
        flex: 1
      },
      {
        field: 'state',
        headerName: 'State',
        flex: 1,
        hide: true
      },
      {
        field: 'zip',
        headerName: 'Postal Code',
        flex: 1,
        hide: true
      },
      {
        field: 'jurisdiction',
        headerName: 'Jurisdiction',
        flex: 1
      },
      {
        field: 'PercentageOfTeamsFromThisJurisdiction',
        headerName: 'Jurisdiction Percentage',
        flex: 1,
        hide: true,
        align: 'right',
        renderCell: params => `${params.row.PercentageOfTeamsFromThisJurisdiction}%`,
        valueGetter: params => (params.row.PercentageOfTeamsFromThisJurisdiction)
      },
      {
        field: 'PercentageOfTeamsFromThisJurisdictionIfAccepted',
        headerName: 'Jurisdiction Percentage If Accepted',
        flex: 1,
        hide: true,
        align: 'right',
        renderCell: params => `${params.row.PercentageOfTeamsFromThisJurisdictionIfAccepted}%`,
        valueGetter: params => (params.row.PercentageOfTeamsFromThisJurisdictionIfAccepted)
      },
      {
        field: 'legalSystem',
        headerName: 'Legal System',
        flex: 1
      },
      {
        field: 'previousMootCount',
        headerName: 'Previous Moots',
        flex: 1,
        type: 'number',        
        valueGetter: params => previousMootCountNumber(params.row.previousMootCount),
        renderCell: params => params.row.previousMootCount,
      },
      {
        field: 'teamOwnerUserId',
        headerName: 'Contact Id',
        flex: 1,
        hide: true,
      },
      {
        field: '',
        headerName: 'Contact',
        flex: 1,
        hide: true,
        valueGetter: params => params.row.registrantFirstName + ' ' + params.row.registrantLastName
      },
      {
        field: 'teamOwnerEmail',
        headerName: 'Email',
        flex: 1,
        hide: true,
        renderCell: params => <a href={`mailto:${params.row.teamOwner.email}`}>{params.row.teamOwner.email}</a>,
        valueGetter: params => (params.row.teamOwner.email)
      },
      {
        field: 'registrantPhone',
        headerName: 'Phone',
        flex: 1,
        hide: true,
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        flex: 1,
        hide: true,
        valueGetter: params => format(parseISO(params.row.createdAt),'yyyy-MM-dd')
      },
      {
        field: 'teamMembers',
        headerName: 'Going to HK Count',
        flex: 1,
        hide: true,
        align: 'right',
        valueGetter: params => inPersonHeadCount(params.row.members),
      },
      {
        field: 'claimantDoc',
        headerName: 'Claimant Memo',
        flex: 1,
        hide: false,
        renderCell: params => {
          const doc = head(filter(d => d.documentType === 'CLAIMANT',params.row.TeamDocuments))
          return doc ? <Link component='button' underline='always' onClick={async (e) => await downloadDocument(doc.id,'CLAIMAINT',params.row.school)}>download</Link> : <span></span>;
        },
        valueGetter: params => head(filter(d => d.documentType === 'CLAIMANT',params.row.TeamDocuments))
      },
      {
        field: 'claimantDocCreatedAt',
        headerName: 'Claimant Memo Created At HKT',
        flex: 1,
        hide: true,      
        valueGetter: params => {
          const doc = head(filter(d => d.documentType === 'CLAIMANT',params.row.TeamDocuments))
          return doc ? format(utcToZonedTime(doc.createdAt,'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm') : '';
        },
      },
      {
        field: 'claimantDocUpdatedAt',
        headerName: 'Claimant Memo Updated At HKT',
        flex: 1,
        hide: true,
        valueGetter: params => {
          const doc = head(filter(d => d.documentType === 'CLAIMANT',params.row.TeamDocuments))
          return doc ? format(utcToZonedTime(doc.updatedAt,'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm') : '';          
        },        
      },
      {
        field: 'respondentDoc',
        headerName: 'Respondent Memo',
        flex: 1,
        hide: true,
        renderCell: params => {
          const doc = head(filter(d => d.documentType === 'RESPONDENT',params.row.TeamDocuments))
          return doc ? <Link component='button' underline='always' onClick={async (e) => await downloadDocument(doc.id,'RESPONDENT',params.row.school)}>download</Link> : <span></span>;
        },
        valueGetter: params =>  head(filter(d => d.documentType === 'RESPONDENT',params.row.TeamDocuments))

      },
      {
        field: 'respondentDocCreatedAt',
        headerName: 'Respondent Memo Created At HKT',
        flex: 1,
        hide: true,
        valueGetter: params => {
          const doc = head(filter(d => d.documentType === 'RESPONDENT',params.row.TeamDocuments))
          return doc ? format(utcToZonedTime(doc.createdAt,'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm') : '';
        },      
      },
      {
        field: 'respondentDocUpdatedAt',
        headerName: 'Respondent Memo Updated At HKT',
        flex: 1,
        hide: true,
        valueGetter: params => {
          const doc = head(filter(d => d.documentType === 'RESPONDENT',params.row.TeamDocuments))
          return doc ? format(utcToZonedTime(doc.updatedAt,'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm') : '';          
        },        
      },
      {
        field: 'paymentStatus',
        headerName: 'Application Status',
        flex: 1,
        valueGetter: params => {
            if (isEmpty(params.row.Payments) && params.row.paymentType !== 'bankTransfer') {
                return 'In progress'
            } else if (params.row.paymentType === 'bankTransfer') {
                return "Bank Transfer"
            } else if (isNotEmpty(params.row.Payments)) {
                return `Paid: Paypal`
            }
        }
      },
      {
        field: 'balance',
        headerName: 'Balance Due',
        flex: 1,
        align: 'right',
        renderCell: params => {
          return formatCurrency(get('balance',last(params.row.PaymentHistory)))
        },
        valueGetter: params => get('balance',last(params.row.PaymentHistory))
      },
      {
        field: 'status',
        headerName: 'Approval Status',
        flex: 1
      },
      {
        field: 'hideTeamName',
        headerName: 'Hide team name',
        flex: 1,
        hide: true
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        getActions: params => ([
          <GridActionsCellItem
              icon={getAcceptIcon(params.row)}
              disabled={params.row.status === 'accepted'}
              onClick={() => onSetTeamStatus(params.row.id, 'accepted')}
              label="Accept"/>
          ,
          <GridActionsCellItem
              icon={
                <Tooltip title='Hold'><Pause
                    htmlColor={params.row.status === 'hold' ? '' : 'darkgoldenrod'}/></Tooltip>}
              title='Hold'
              disabled={params.row.status === 'hold'}
              onClick={() => onSetTeamStatus(params.row.id, 'hold')}
              label="Hold"/>
          ,
          <GridActionsCellItem
              icon={<Tooltip title='Deny'><DoNotDisturb
                  htmlColor={params.row.status === 'denied' ? '' : 'red'}/></Tooltip>}
              title='Deny'
              disabled={params.row.status === 'denied'}
              onClick={() => onSetTeamStatus(params.row.id, 'denied')}
              label="Deny"/>,
          <GridActionsCellItem
              icon={<Tooltip title='Payment & Finances'><Payment/></Tooltip>}
              title='Payment & Finances'
              onClick={() => onEditPayments(params.row.id)}
              label="Payment & Finances"/>

        ])
      },
      {
        field: 'delete',
        hide: true,
        type: "actions",
        headerName: 'Delete',
        getActions: params => ([<GridActionsCellItem
            icon={
              <Tooltip title='Delete'>
                <Delete/>
              </Tooltip>
            }
            onClick={() => {
              const result = window.confirm(`Are you sure you want to delete ${params.row.school}? `);
              if (result) {
                onSetTeamStatus(params.row.id, 'delete')
              }
            }}
            label="Delete"/>])
      }
      ],[teams])

    return (
        <div style={{height: 700, width: '100%'}}>
          <DataGridPro
              loading={busy}
              rows={teams}
              columns={columns}
              disableSelectionOnClick
              localeText={{ toolbarExport: 'Export Schools' }}
              components={{
                Toolbar: CustomToolbar,
                Footer: CustomFooter,
              }}
          />
        </div>
    )
  }



TeamsTable.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object),
  getTeams: PropTypes.func,
  setTeamStatus: PropTypes.func,
  exportAllTeamMembers: PropTypes.func,
  exportAllTeamPaymentRecords: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
      teams: state.admin.teams
    }),{
      getTeams: getAllTeams,
      setTeamStatus: setTeamStatus,
      exportAllTeamMembers: exportAllTeamMembers,
      exportAllTeamPaymentRecords: exportAllTeamPaymentRecords,
      downloadTeamDocument: downloadTeamDocument
    })(TeamsTable)