import React from 'react'
import ReactDOM from 'react-dom'
import 'styles/index.css'
import App from 'App'
import 'cross-fetch/polyfill';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import PropTypes from 'prop-types'

import {Switch, Route} from "react-router-dom";
import TeamRegistration from "./components/registration/TeamRegistration";
import Registration from "./components/registration";
import ArbitratorRegistration from "./components/registration/ArbitratorRegistration";
import TeamAcceptedForReview from "./components/team/TeamAcceptedForReview";
import ArbitratorAcceptedForReview from "./components/arbitrator/ArbitratorAcceptedForReview";
import Login from "./components/login/Signin";
import PasswordReset from "./components/login/PasswordReset";
import PasswordSent from "./components/login/PasswordSent";
import PasswordResetComplete from "./components/login/PasswordResetComplete";
import AdminDashboard from "./components/dashboard/admin";
import TeamOwnerDashboard from "./components/dashboard/teamOwner";
import ArbitratorDashboard from "./components/dashboard/arbitrator";
import TeamMembers from "./components/team/Member";
import { createBrowserHistory } from 'history'
import configureStore from './configureStore'
import ClarificationRequest from './components/team/ClarificationRequest'
import UploadDocument from './components/team/UploadDocument'
import OralArguments from './components/team/OralArguments'
import AuthenticatedRoute from "./AuthenticatedRoute";
import Home from "./Home";
import {TEAM_OWNER, TEAM_MEMBER, ADMIN, ARBITRATOR, SCORER} from './consts/roles'
import ArbitratorAvailabilityForm from "./components/arbitrator/AvailabilityForm";
import ArbitratorPairings from "./components/arbitrator/Pairings";
import OralArgumentsDashboard from "./components/dashboard/admin/OralArgumentsDashboard";
import ArbitratorOralArgumentSchedule from "./components/arbitrator/OralArgumentsSchedule";
import EditPairing  from "./components/dashboard/admin/OralArgumentsDashboard/EditPairing";
import ScorePairing  from "./components/dashboard/admin/OralArgumentsDashboard/ScorePairing";
import SelectWinner from "./components/dashboard/admin/OralArgumentsDashboard/SelectWinner";
import ExistingAccount from "./components/registration/ExistingAccount";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import TeamSignUp from "./components/login/TeamSignUp";
import ArbitratorSignUp from "./components/login/ArbitratorSignUp";
import TeamPayments from "./components/team/Payments"
import AdminTeamPayments from "./components/dashboard/admin/AdminTeamPayments";
import AdminMemoRankings from "./components/dashboard/adminMemoRankings";
import AdminMemoAssignments from "./components/dashboard/adminMemoAssignments";
import AddAssignment from "./components/dashboard/adminMemoAssignments/AddAssignment";
import SettingsDashboard from "./components/settings";
import Moot from "./components/settings/Moot";

export const history = createBrowserHistory()

const persistedState = {}//loadState()
const store = configureStore(persistedState, history)

const Root = ({ store }) => (
    <Provider store={store}>
    <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
        <App component={App}>
        
            <Switch>
                <AuthenticatedRoute exact path='/' component={Home} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/login/password-reset' component={PasswordReset} />
                <Route exact path='/login/password-reset/sent' component={PasswordSent} />
                <Route exact path='/login/password-reset/complete' component={PasswordResetComplete} />
                <Route exact path='/TeamSignUp' component={TeamSignUp} />
                <Route exact path='/ArbitratorSignUp' component={ArbitratorSignUp} />
                <Route exact path='/existingAccount' component={ExistingAccount} />
                <Route exact path='/registration' component={Registration} />

                <Route exact path='/registration/team-accepted' component={TeamAcceptedForReview} />
                <Route exact path='/registration/arbitrator-accepted' component={ArbitratorAcceptedForReview} />

                <AuthenticatedRoute allowedRoles={[]} exact path='/registration/arbitrator' component={ArbitratorRegistration} />
                <AuthenticatedRoute allowedRoles={[]} exact path='/registration/team' component={TeamRegistration} />

                <AuthenticatedRoute exact allowedRoles={[ADMIN]}  path='/admin' component={AdminDashboard} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN]} path='/admin/memoRankings' component={AdminMemoRankings} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN]} path='/admin/memoAssignments' component={AdminMemoAssignments} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN]} path='/admin/memoAssignments/add' component={AddAssignment} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN]} path='/admin/team-members/:teamId' component={TeamMembers} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN]} path='/admin/team/payments/:teamId' component={AdminTeamPayments} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN,SCORER]} path='/admin/oral-arguments/' component={OralArgumentsDashboard} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN]} path='/admin/oral-arguments/edit/:pairingId' component={EditPairing} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN,SCORER]} path='/admin/oral-arguments/score/:pairingId' component={ScorePairing} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN,SCORER]} path='/admin/oral-arguments/selectWinner/:pairingId' component={SelectWinner} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN]} path='/admin/settings' component={SettingsDashboard} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN]} path='/admin/moot/edit/:mootId' component={Moot} />
                <AuthenticatedRoute exact allowedRoles={[ADMIN]} path='/admin/moot/new' component={Moot} />
                <AuthenticatedRoute exact allowedRoles={[ARBITRATOR]} path='/arbitrator' component={ArbitratorDashboard} />
                <AuthenticatedRoute exact allowedRoles={[ARBITRATOR]} path='/arbitrator/availability' component={ArbitratorAvailabilityForm} />
                <AuthenticatedRoute exact allowedRoles={[ARBITRATOR]} path='/arbitrator/pairings' component={ArbitratorPairings} />
                <AuthenticatedRoute exact allowedRoles={[ARBITRATOR]} path='/arbitrator/oral-arguments' component={ArbitratorOralArgumentSchedule} />
                <AuthenticatedRoute exact  allowedRoles={[TEAM_OWNER ]} path='/team-owner' component={TeamOwnerDashboard} />
                <AuthenticatedRoute exact allowedRoles={[TEAM_OWNER, TEAM_MEMBER]} path='/team/clarification-request' component={ClarificationRequest} />
                <AuthenticatedRoute exact allowedRoles={[TEAM_OWNER]} path='/team/members' component={TeamMembers} />
                <AuthenticatedRoute exact allowedRoles={[TEAM_OWNER]} path='/team/documents' component={UploadDocument} />
                <AuthenticatedRoute exact allowedRoles={[TEAM_OWNER]} path='/team/oral-arguments' component={OralArguments} />
                <AuthenticatedRoute exact allowedRoles={[TEAM_OWNER]} path='/team/payments' component={TeamPayments} />
            </Switch>
        </App>
        </ThemeProvider>
    </ConnectedRouter>
</Provider>)


Root.propTypes = {
    store: PropTypes.object.isRequired
}

ReactDOM.render(<Root store={store} />, document.getElementById('root'))
