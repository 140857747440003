import React, {useEffect, useState} from 'react'
import {connect} from "react-redux"
import { useHistory } from "react-router"
import { Grid,Container, Typography} from "@material-ui/core"
import {noop} from "lodash/fp"
import SignUp from "./SignUp"
import {format, utcToZonedTime} from "date-fns-tz";
import ordinal from "ordinal-number-suffix";
import {getCurrentMoot,getIsTeamRegistrationOpen} from "../../reducers/mootSelectors";
import {shouldOverride} from "../helpers";

const TeamSignUp = ({ router,
                        getIsTeamRegistrationOpen = noop,
                        getCurrentMoot = noop,
                        currentMoot,
                        isTeamRegistrationOpen = {}}) => {
    const [isLoading,setIsLoading] = useState(false)


    const history = useHistory()
    const returnTo = router.location.query.returnTo
    const overrideCode = router.location.query.override

    useEffect(() => {
        const asyncFetchData = async () => {
            setIsLoading(true)
            try{
                await getCurrentMoot()
                await getIsTeamRegistrationOpen()
            }catch(e){
            }
            setIsLoading(false)
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleSignUpComplete = result => {
        if (result === 'UsernameExistsException'){
            history.push(`/existingAccount${returnTo ? `?returnTo=${returnTo}` : ''}}`)
        }else{
            history.push(`${returnTo}${overrideCode ? `?override=${overrideCode}` : ''}`)
        }
    }

  return (
      <Container style={{ paddingBottom: '10rem' }}>
          { !isLoading && (isTeamRegistrationOpen.notOpenYet === true && !shouldOverride(overrideCode,currentMoot)) &&
              <Grid container style={{flexGrow: 1, padding: '20px'}} spacing={2}>
                  <Grid item xs={12}>
                      <Typography component='h1' variant='h4'>Registration for Teams will open on {format(utcToZonedTime(currentMoot.teamRegistrationOpenDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy p')} (HKT)</Typography>
                  </Grid>
              </Grid>
          }
          { !isLoading && (isTeamRegistrationOpen.closed === true &&  !shouldOverride(overrideCode,currentMoot)) &&
              <Grid container style={{flexGrow: 1, padding: '20px'}} spacing={2}>
                  <Grid item xs={12}>
                    <Typography component='h1' variant='h4'>Registration for Teams is now closed</Typography>
                    {/* 
                      <Typography component='h1' variant='h4'>Team Registration for the {ordinal(isTeamRegistrationOpen.moot.mootNumber)} Vis East Moot will commence at {format(utcToZonedTime(isTeamRegistrationOpen.moot.teamRegistrationOpenDate,'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy p')}(HKT)!</Typography>
                    */}
                  </Grid>
              </Grid>
          }
          {!isLoading && ((isTeamRegistrationOpen.notOpenYet === false && isTeamRegistrationOpen.closed === false) || shouldOverride(overrideCode,currentMoot)) &&

              <Grid container
                    style={{flexGrow: 1, padding: '20px'}}
                    spacing={2}>
                  <Grid item xs={12}>
                      <Typography variant='h4'>Team Registration</Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <Typography variant='h6'>Create Team Account</Typography>
                      <Typography variant='body1'>Welcome to the {ordinal(isTeamRegistrationOpen?.moot?.mootNumber)} Vis East Moot Competition. Please enter the
                          email you wish to use as the authorized email for your Team Account and create a password
                          to start the registration process.</Typography>
                  </Grid>
                  <SignUp onSignUpComplete={handleSignUpComplete}/>
              </Grid>
          }

      </Container>)
}


export default connect(
    (state, ownProps) => ({
        router: state.router,
        currentMoot: state.moot.currentMoot,
        isTeamRegistrationOpen: state.moot.currentMoot.isTeamRegistrationOpen
    }), {
        getIsTeamRegistrationOpen: getIsTeamRegistrationOpen,
        getCurrentMoot: getCurrentMoot
    }
)(TeamSignUp)
