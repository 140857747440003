import Container from '@material-ui/core/Container'
import React from 'react'
import {Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '../common/TabPanel'
import MootTable from "./MootTable";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const SettingsDashboard = () => {

    const classes = useStyles()
    const [tabValue, setTabValue] = React.useState(0)

    const handleTabChange = (event, newValue) => setTabValue(newValue)


    return (
        <Container maxWidth='xl' className={classes.root}>
            <Typography variant='h4'>Settings</Typography>
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label='Moots' />
                <Tab label='School List' />
                <Tab label='Jurisdiction List' />
                {/*<Tab label='Text Areas' />*/}
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <MootTable />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>

            </TabPanel>
            <TabPanel value={tabValue} index={2}>

            </TabPanel>
            {/*<TabPanel value={tabValue} index={3}>*/}
            {/*    <Pairing />*/}
            {/*</TabPanel>*/}
        </Container>
    )
}


export default SettingsDashboard
