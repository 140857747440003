import React, {useState} from 'react'

import _ from 'lodash/fp'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getSelectedMoot} from "../../reducers/mootSelectors";
import { saveMoot } from '../../actions/mootActions'
import {Button, Chip, CircularProgress, Snackbar, TextField as MUITextField, Typography} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextInput from "../common/TextInput";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {
    DatePicker,
    DesktopDateTimePicker,
    LocalizationProvider,
    TimePicker
} from "@mui/x-date-pickers";
import {add, addDays, format, parseISO} from "date-fns";
import './Moot.css'
import {isNotEmpty, mapWithIndex} from "../../utils/funcUtils";
import {Link, useHistory} from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {Create} from "@material-ui/icons";
import {Edit, Start} from "@mui/icons-material";
import {Alert} from "@mui/material";
import {
    asHongKongDate,
    asHongKongDateString,
    formatInTimeZone,
    hongKongTimeFromUTC,
    utcFromHongKongTime
} from "../../utils/dateUtils";
import {utcToZonedTime} from "date-fns-tz";

const Moot = ({selectedMootId, selectedMoot, getSelectedMoot = _.noop, saveMoot, saveMootError}) => {

    const ROUND_TYPES = {
        GENERAL_ROUNDS: 'General Rounds',
        ROUND_OF_32: 'Elimination Rounds: Round of 32',
        ROUND_OF_16: 'Elimination Rounds: Round of 16',
        QUARTERFINALS: 'Elimination Rounds: Quarter-Finals',
        SEMIFINALS: 'Elimination Rounds: Semi-Finals',
        FINALS: 'Finals',
        CHAMPION: 'Champion'
    }

    const history = useHistory()

    const [busy, setBusy] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const [saved, setSaved] = React.useState(false)
    const [newMoot, setNewMoot] = useState({
        mootLocation: 'Hong Kong',
        mootLocalTimezone: 'Asia/Hong_Kong'
    })
    const [scheduleStartDate, setScheduleStartDate] = useState()
    const [schedule, setSchedule] = useState([])
    const [newTimes, setNewTimes] = useState({})
    const [toggleAdd, setToggleAdd] = useState()

    const formatScheduleDate = date => {
        return format(date, 'dd MMMM, EEEE')
    }


    const buildScheduleFromMootRounds = moot => {
        //Given the existing mootRounds build the schedule structure
        const newSchedule = _.pipe(
            _.map(round => ({
                date: { display: formatInTimeZone(parseISO(round.date),'dd MMMM, EEEE',"Asia/Hong_Kong"), value: round.date},
                roundType: round.roundType
            })),
            _.groupBy(item => `${item.date.display}-${item.roundType}`),
            _.map(times => {
                const roundTimes = _.map(t => (
                    {display: formatInTimeZone(parseISO(t.date.value),'hh:mm aa',"Asia/Hong_Kong"), value: t.date.value}
                ), times)

                return {
                    date: _.head(times)?.date,
                    times: roundTimes,
                    roundType: ROUND_TYPES[_.head(times)?.roundType]
                }
            })
        )(moot?.MootRound)
        setSchedule(newSchedule)
    }
    const buildGeneralRoundTimeObjects = startDate => {
        let result = []

        const firstTime = add(startDate, {hours: 8, minutes: 30})
        const secondTime = add(startDate, {hours: 10, minutes: 30})
        const thirdTime = add(startDate, {hours: 12, minutes: 30})
        const fourthTime = add(startDate, {hours: 14, minutes: 30})
        const fifthTime = add(startDate, {hours: 16, minutes: 30})

        result.push({
            display: format(firstTime, 'hh:mm aa'),
            value: utcFromHongKongTime(firstTime)
        })
        result.push({
            display: format(secondTime, 'hh:mm aa'),
            value: utcFromHongKongTime(secondTime)
        })
        result.push({
            display: format(thirdTime, 'hh:mm aa'),
            value: utcFromHongKongTime(thirdTime)
        })
        result.push({
            display: format(fourthTime, 'hh:mm aa'),
            value: utcFromHongKongTime(fourthTime)
        })
        result.push({
            display: format(fifthTime, 'hh:mm aa'),
            value: utcFromHongKongTime(fifthTime)
        })

        return result
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    const buildSchedule = (scheduleStartDate) => {

        if (!scheduleStartDate)
            return []

        const newSchedule = []
        // The general rounds schedule is set to be startDate + the next 3 days
        // The time intervals is 8:30 10:30 12:30 2:30 4:30
        buildGeneralRoundTimeObjects(scheduleStartDate)

        newSchedule.push({
            date: { display: formatScheduleDate(scheduleStartDate), value: utcFromHongKongTime(scheduleStartDate)},
            times: buildGeneralRoundTimeObjects(scheduleStartDate),
            roundType: ROUND_TYPES.GENERAL_ROUNDS
        })
        newSchedule.push({
            date:  { display: formatScheduleDate(addDays(scheduleStartDate, 1)), value: utcFromHongKongTime(addDays(scheduleStartDate, 1))},
            times: buildGeneralRoundTimeObjects(addDays(scheduleStartDate, 1)),
            roundType: ROUND_TYPES.GENERAL_ROUNDS
        })
        newSchedule.push({
            date: { display: formatScheduleDate(addDays(scheduleStartDate, 2)), value: utcFromHongKongTime(addDays(scheduleStartDate, 2))},
            times: buildGeneralRoundTimeObjects(addDays(scheduleStartDate, 2)),
            roundType: ROUND_TYPES.GENERAL_ROUNDS
        })
        newSchedule.push({
            date: { display: formatScheduleDate(addDays(scheduleStartDate, 3)), value: utcFromHongKongTime(addDays(scheduleStartDate, 3))},
            times: buildGeneralRoundTimeObjects(addDays(scheduleStartDate, 3)),
            roundType: ROUND_TYPES.GENERAL_ROUNDS
        })
        //Elimination Round of 32 is startDate + 4
        // Time intervals is 9:00 11:00
        newSchedule.push({
            date: { display: formatScheduleDate(addDays(scheduleStartDate, 4)), value: utcFromHongKongTime(addDays(scheduleStartDate, 4))},
            times: [
                {
                display: format(add(addDays(scheduleStartDate, 4), {hours: 9}), 'hh:mm aa'),
                value: utcFromHongKongTime(add(addDays(scheduleStartDate, 4), {hours: 9}))
                },
                {
                    display: format(add(addDays(scheduleStartDate, 4), {hours: 11}), 'hh:mm aa'),
                    value: utcFromHongKongTime(add(addDays(scheduleStartDate, 4), {hours: 11}))
                }
            ], roundType: ROUND_TYPES.ROUND_OF_32
        })

        //Elimination Round of 16 is startDate +4
        // Time interval is 3:00
        newSchedule.push({
            date: { display: formatScheduleDate(addDays(scheduleStartDate, 4)), value: utcFromHongKongTime(addDays(scheduleStartDate, 4))},
            times: [
                {
                    display: format(add(addDays(scheduleStartDate, 4), {hours: 15}), 'hh:mm aa'),
                    value: utcFromHongKongTime(add(addDays(scheduleStartDate, 4), {hours: 15}))
                }
            ],
            roundType: ROUND_TYPES.ROUND_OF_16
        })

        //Elimination Round Quarter finals is startDate + 5
        // Time is 9:00
        newSchedule.push({
            date: { display: formatScheduleDate(addDays(scheduleStartDate, 5)), value: utcFromHongKongTime(addDays(scheduleStartDate,5))},
            times: [
                {
                    display: format(add(addDays(scheduleStartDate, 5), {hours: 9}), 'hh:mm aa'),
                    value: utcFromHongKongTime(add(addDays(scheduleStartDate, 5), {hours: 9}))
                },

            ],
            roundType: ROUND_TYPES.QUARTERFINALS
        })

        //Elimination Round Semi finals is startDate + 5
        // Time is 1:00
        newSchedule.push({
            date: { display: formatScheduleDate(addDays(scheduleStartDate, 5)),value: utcFromHongKongTime(addDays(scheduleStartDate,5))},
            times: [
                {
                    display: format(add(addDays(scheduleStartDate, 4), {hours: 13}), 'hh:mm aa'),
                    value: utcFromHongKongTime(add(addDays(scheduleStartDate, 4), {hours: 13}))
                }
            ],
            roundType: ROUND_TYPES.SEMIFINALS
        })

        //Finals is startDate + 6
        // Time is 1:00
        newSchedule.push({
            date: { display: formatScheduleDate(addDays(scheduleStartDate, 6)), value: utcFromHongKongTime(addDays(scheduleStartDate,6))},
            times: [
                {
                    display: format(add(addDays(scheduleStartDate, 6), {hours: 13}), 'hh:mm aa'),
                    value: utcFromHongKongTime(add(addDays(scheduleStartDate, 6), {hours: 13}))
                }
            ],
            roundType: ROUND_TYPES.FINALS
        })

        console.log(newSchedule)
        setSchedule(newSchedule)
    }


    const handleRemoveScheduleTime = (date, time) => {
        const newSchedule = _.map(s => {
            if (s.date === date) {
                const times = _.filter(t => t !== time, s.times)
                return {
                    ...s,
                    times
                }
            } else
                return s
        }, schedule)

        setSchedule(newSchedule)
    }

    const addScheduleTime = (date, time, roundType) => {
        const newSchedule = _.map(s => {
            if (s.date.display === date && s.roundType === roundType) {
                const newTimes = [...s.times, {display: format(time, 'h:mm aa'), value: utcFromHongKongTime(time).toISOString()}]
                const sortedTimes = _.sortBy(t => t.value,newTimes)
                return {
                    ...s,
                    times: sortedTimes
                }
            } else
                return s
        }, schedule)

        setSchedule(newSchedule)
    }

    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setBusy(true)
                if (selectedMootId) {
                    const moot = await getSelectedMoot(selectedMootId)
                    buildScheduleFromMootRounds(moot)
                    setNewMoot(moot)
                }
                setBusy(false)
            } catch (e) {
                //TODO ?
                setBusy(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const canNoLongerAdd = (roundType,date) => {
        const found = _.find(s => s.date === date, schedule)
        switch (roundType) {
            case ROUND_TYPES.ROUND_OF_32:
                return _.size(found.times) >= 16
            case ROUND_TYPES.ROUND_OF_16:
                return _.size(found.times) >= 8
            case ROUND_TYPES.QUARTERFINALS:
                return _.size(found.times) >= 4
            case ROUND_TYPES.SEMIFINALS:
                return _.size(found.times) >= 2
            case ROUND_TYPES.FINALS:
                return _.size(found.times) >= 1
            default:
                return false
        }
    }

    const save = async e => {
        e.preventDefault()
        try {
            if (!saving) {
                setSaving(true)
                const result = await saveMoot(newMoot, schedule)
                if (isNotEmpty(result)) {
                    setSaved(true)
                }
                setSaving(false)
            }
        }catch(e){
            setSaving(false)
        }
    }
    return (
        <Container style={{
            background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"
        }}>
            {busy &&
                <CircularProgress/>
            }
            {!busy &&
                <>
                <Button style={{ margin: '0 0 2rem 0' }} variant='text' component={Link} onClick={() => history.push('/admin/settings')} >
                    <ArrowBackIosIcon /> Back to Settings
                </Button>

                <form onSubmit={save}>
                    <Grid container spacing={2}
                          style={{flexGrow: 1, padding: '20px'}}>
                        <Grid item xs={4} md={4}>
                            <TextInput
                                label='Moot Number'
                                onChange={e => setNewMoot({
                                    ...newMoot,
                                    mootNumber: e.target.value
                                })}
                                value={newMoot.mootNumber}
                            />
                        </Grid>
                        {/* We have these fields in the DB but don't currently do anything with them so leaving this
                        code here if we revisit letting you modify these values*/}
                        {/*<Grid item xs={4} md={4}>*/}
                        {/*    <TextInput*/}
                        {/*        label='Moot Location'*/}
                        {/*        onChange={e => setNewMoot({*/}
                        {/*            ...newMoot,*/}
                        {/*            mootLocation: e.target.value*/}
                        {/*        })}*/}
                        {/*        value={newMoot.mootLocation || 'Hong Kong'}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4} md={4}>*/}
                        {/*    <Autocomplete*/}
                        {/*        onChange={(event, newValue) => setNewMoot({*/}
                        {/*            ...newMoot,*/}
                        {/*            mootLocalTimezone: newValue*/}
                        {/*        })}*/}
                        {/*        value={newMoot.mootLocalTimezone || 'Asia/Hong_Kong'}*/}
                        {/*        options={Intl.supportedValuesOf('timeZone')}*/}
                        {/*        autoSelect*/}
                        {/*        renderInput={(params) => <MUITextField {...params} variant="standard" label="Moot Local Timezone" required />}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={8} md={8}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Moot Open Date (HKT)"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.mootStartDate) || null}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        mootStartDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={8} md={8}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Moot Close Date (HKT)"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.mootEndDate) || null}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        mootEndDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <TextInput
                                label='Open Override Code'
                                value={newMoot.openOverrideCode}
                                onChange={e => setNewMoot({
                                    ...newMoot,
                                    openOverrideCode: e.target.value
                                })
                                }/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Oral Arguments Schedule</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>ALL TIMES reflect Hong Kong Time (GMT+8). The ability to edit
                                this section will be disabled when the arbitrator registration open date
                                occurs</Typography>
                        </Grid>
                        { _.isEmpty(schedule) &&
                        <Grid item xs={8} md={8}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="General Round Start Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={scheduleStartDate || null}
                                    renderInput={(params) => <MUITextField {...params}  inputProps={{
                                        ...params.inputProps,
                                        readOnly: true}} />}
                                    onChange={e => {
                                        buildSchedule(e)
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        }
                        { isNotEmpty(schedule) &&
                            <Button color="primary"
                                    style={{ margin: '.5rem 0'}}
                                    size="medium"
                                    variant='outlined'
                                    startIcon={<Start />}
                                    aria-haspopup="menu"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setSchedule([])
                                    }}
                            >Clear schedule and start over</Button>
                        }

                        {mapWithIndex((s, i) => {
                                return (<Grid key={`${s.date.value}-${s.roundType}`} item xs={12} md={12}>
                                    {i === 0 &&
                                        <Typography style={{marginBottom: '1rem'}} variant='h5'>{s.roundType}</Typography>
                                    }
                                    {i > 0 && s.roundType !== schedule[i - 1]?.roundType &&
                                        <Typography style={{marginBottom: '1rem'}} variant='h5'>{s.roundType}</Typography>
                                    }
                                    <span className='schedule-date'>{s.date.display}</span>
                                    {
                                        <>
                                            { mapWithIndex((t, idx) => (
                                                <>
                                                    <Chip className='schedule-time' style={{marginLeft: "1rem"}}
                                                          key={`${s.date.value}-${t.value}`} label={t.display}
                                                          onDelete={() => handleRemoveScheduleTime(s.date, t)}/>
                                                </>
                                            )
                                            , s.times)
                                            }
                                            { toggleAdd !== s.date.display &&
                                            <Button onClick={() => {setToggleAdd(s.date.display)}}>+ Add</Button>
                                            }
                                            { toggleAdd === s.date.display &&
                                            <span className='schedule-time--add'>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <TimePicker
                                                        minutesStep={5}
                                                        value={newTimes[`${s.date.display}-${s.roundType}`] ?? hongKongTimeFromUTC(s.date.value) }
                                                        onChange={e => setNewTimes({[`${s.date.display}-${s.roundType}`]: e})}
                                                        renderInput={(params) => <MUITextField {...params} />}/>
                                                </LocalizationProvider>
                                                <Button
                                                    disabled={canNoLongerAdd(s.roundType,s.date)}
                                                    onClick={() => {
                                                        addScheduleTime(s.date.display, newTimes[`${s.date.display}-${s.roundType}`],s.roundType)
                                                    }}>+ Add</Button>
                                        </span>
                                            }

                                        </>
                                    }
                                </Grid>)
                            }
                            , schedule)
                        }

                        <Grid item xs={12}>
                            <hr/>
                            <Typography variant='h5'>Team Settings</Typography>
                        </Grid>
                            <Grid item xs={4} md={4}>
                                <TextInput
                                    label='In Person Registration Fee'
                                    value={newMoot.inPersonRegistrationFee}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        inPersonRegistrationFee: e.target.value
                                    })
                                    }/>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextInput
                                    label='Standard Team Deposit'
                                    value={newMoot.teamDepositAmount}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        teamDepositAmount: e.target.value
                                    })
                                    }/>
                            </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Team Registration Open"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.teamRegistrationOpenDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        teamRegistrationOpenDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Team Registration Close"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.teamRegistrationCloseDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        teamRegistrationCloseDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Team Member Open"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.teamMemberOpenDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        teamMemberOpenDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Team Member Close"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.teamMemberCloseDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        teamMemberCloseDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Clarification Open"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.clarificationOpenDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        clarificationOpenDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Clarification Close"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.clarificationCloseDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        clarificationCloseDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Claimant Memo Open"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.claimantDocumentUploadOpenDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        claimantDocumentUploadOpenDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Claimant Memo Close"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.claimantDocumentUploadEndDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        claimantDocumentUploadEndDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Respondent Memo Open"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.respondentDocumentUploadOpenDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        respondentDocumentUploadOpenDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Respondent Memo Close"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.respondentDocumentUploadEndDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        respondentDocumentUploadEndDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Show Team Oral Details"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.showOralDetailsTeamOpenDateValue)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        showOralDetailsTeamOpenDateValue: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Critique Available"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.critiqueAvailableDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        critiqueAvailableDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <hr/>
                            <Typography variant='h5'>Arbitrator Settings</Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Arbitrator Registration Open"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.arbitratorRegistrationOpenDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        arbitratorRegistrationOpenDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Arbitrator Registration Close"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.arbitratorRegistrationCloseDate)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        arbitratorRegistrationCloseDate: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Show Arbitrator Oral Details"
                                    inputFormat="MM/dd/yyyy hh:mm aa"
                                    value={hongKongTimeFromUTC(newMoot.showOralDetailsArbitratorsOpenDateValue)}
                                    renderInput={(params) => <MUITextField {...params} />}
                                    onChange={e => setNewMoot({
                                        ...newMoot,
                                        showOralDetailsArbitratorsOpenDateValue: utcFromHongKongTime(e)
                                    })
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid tem xs={12} style={{marginTop: "1rem", display:"flex", gap:"10px"}}>
                            <Button disabled={saving} type="submit" variant='contained' color="primary">SAVE</Button>
                            {saving && <CircularProgress size={35}/>}
                            {saveMootError && <Typography color='error'>{saveMootError}</Typography>}
                        </Grid>
                    </Grid>
                    <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000} >
                        <Alert onClose={handleClose}  severity="success" sx={{ width: '100%' }}>
                            Moot saved
                        </Alert>
                    </Snackbar>


                </form>
                </>
            }


        </Container>

    )
}


Moot.propTypes = {
    selectedMoot: PropTypes.object,
    getSelectedMoot: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
        selectedMoot: state.moot.selectedMoot,
        selectedMootId: ownProps.match.params.mootId,
        saveMootError: state.moot.saveMootError
    }), {
        getSelectedMoot: getSelectedMoot,
        saveMoot : saveMoot
    })(Moot)
